@import "~antd/dist/antd.css";

@font-face {
  font-family: Sommet;
  src: url("./fonts/Sommet.otf") format("opentype");
}

.landing-page {
  color: floralwhite;
  font-family: "Sommet";
}

.footer {
  background: #001529;
  color: floralwhite;
  flex-wrap: wrap;
}
.footer a {
  color: floralwhite;
}
.footer ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.footer ul li {
  padding: 0 20px;
  color: rgba(238, 238, 238, 0.7);
}
.footer ul li:first-child {
  padding-left: 0;
}

.footer ul li:last-child {
  flex: 1;
  text-align: right;
  font-size: 10px;
  font-weight: 300;
}
.navigation-menu.ant-menu-horizontal {
  border-bottom: 0;
  height: 100%;
  display: flex;
  background: #001529;
}

.navigation-menu.ant-menu-horizontal > .ant-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 4px solid transparent;
  background: #001529;
  color: floralwhite;
}

.navigation-menu.ant-menu-horizontal > .ant-menu-item a {
  color: floralwhite;
}

.navigation-menu.ant-menu-horizontal > li.ant-menu-item:hover,
.navigation-menu.ant-menu-horizontal > li.ant-menu-item-selected {
  border-bottom: 4px solid #1890ff;
}

/* Resolves issue with wildly expanding container height after file drag */
.ant-upload.ant-upload-drag {
  height: auto;
}

/* Hide table placeholder text while loading */
.ant-spin-container.ant-spin-blur .ant-table-placeholder {
  color: transparent;
}

/* Resolves issue with vertical scroll when screen takes up >100vh */
.ant-layout-content {
  min-height: auto;
}

.editable-table-field input,
.editable-table-field .ant-select-selection {
  border: 1px solid transparent;
  cursor: pointer;
  transition-delay: 0s;
  transition-duration: 0.3s;
  background: transparent;
}
.editable-table-field input:hover:not(.ant-select-search__field),
.editable-table-field .ant-select-selection:hover {
  border: 1px solid #40a9ff;
}

.editable-table-button.disabled,
.editable-table-button button:disabled {
  cursor: default !important;
}
.editable-table-button button.ant-btn-danger:disabled {
  color: rgb(245, 34, 45);
}

.contact-card {
  display: inline-block;
  min-width: 400px;
  margin: 0 10px 10px 0;
}
.contact-card .ant-form-item-label {
  font-weight: 500;
}
.contact-card .ant-form-item {
  margin-bottom: 16px;
}
.timeslot {
  display: flex;
  align-items: center;
}
.timeslot .ant-checkbox {
  display: none;
}
.timeslot-field .ant-form-explain {
  margin-top: 3px;
}
.timeslot .ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  transition: 0.1s all;
  background: #eeeeee;
}
.timeslot.weeks .ant-checkbox-wrapper {
  width: 30px;
}
.timeslot.days .ant-checkbox-wrapper {
  width: 40px;
}
.timeslot .ant-checkbox-wrapper-checked {
  background: #1890ff;
  color: white;
}

.timeslot .ant-checkbox-wrapper-disabled {
  cursor: default;
}
.timeslot .ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.65);
  cursor: default;
}
.timeslot .ant-checkbox-checked.ant-checkbox-disabled + span {
  color: white;
}

.user-details .ant-form-item div {
  line-height: 33px;
}

.timeslot-card > div {
  display: flex;
  align-items: center;
}
.timeslot-card > div > div:first-child {
  min-width: 70px;
}
.timeslot-card > div > div:first-child::after {
  content: ":";
}

.staff-card .ant-collapse-header {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.staff-card .ant-collapse-content-box {
  padding-bottom: 0px;
}

.course-table .ant-table {
  overflow-x: scroll;
}

.staff-card.embedded .ant-card-meta-avatar {
  padding-right: 6px;
}
.staff-card.embedded .ant-card-meta-title,
.staff-card.embedded .ant-card-meta-description,
.staff-card.embedded .ant-collapse-header {
  font-size: 13px;
  margin-bottom: 0;
}
.staff-card.embedded .ant-collapse-header .ant-collapse-arrow {
  font-size: 10px;
}
.staff-card img {
  width: auto;
}
